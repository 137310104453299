define("inmelek-ember/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.intl.setLocale('es-es');
    }

  });

  _exports.default = _default;
});