define("inmelek-ember/templates/components/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p+FYjHIe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"footer\",true],[10,\"class\",\"flex flex-col mt-24 mb-32 text-center text-gray-500\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"INMELEK ©\"],[9],[0,\"\\n  \"],[7,\"a\",true],[10,\"class\",\"underline\"],[10,\"href\",\"tel:0034943902658\"],[8],[0,\"(+34) 943 90 26 58\"],[9],[0,\"\\n  \"],[7,\"a\",true],[10,\"class\",\"underline\"],[10,\"href\",\"https://www.inmelek.com\"],[8],[0,\"www.inmelek.com\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inmelek-ember/templates/components/footer.hbs"
    }
  });

  _exports.default = _default;
});