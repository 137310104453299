define("inmelek-ember/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GsGY7g8V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container mx-auto p-6 xl:p-0\"],[8],[0,\"\\n  \"],[5,\"header\",[],[[],[]]],[0,\"\\n  \"],[5,\"hero\",[],[[],[]]],[0,\"\\n  \"],[5,\"jobdescription\",[],[[],[]]],[0,\"\\n  \"],[7,\"hr\",true],[10,\"class\",\"mb-10\"],[8],[9],[0,\"\\n  \"],[5,\"brands\",[],[[],[]]],[0,\"\\n  \"],[7,\"hr\",true],[10,\"class\",\"mt-10 mb-10\"],[8],[9],[0,\"\\n  \"],[5,\"contact\",[],[[],[]]],[0,\"\\n  \"],[5,\"footer\",[],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inmelek-ember/templates/application.hbs"
    }
  });

  _exports.default = _default;
});