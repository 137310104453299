define("inmelek-ember/templates/components/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QN/twxDw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"flex flex-col items-center mt-8\"],[10,\"id\",\"header-with-logo\"],[8],[0,\"\\n  \"],[7,\"img\",true],[10,\"class\",\"w-32\"],[10,\"src\",\"./assets/images/inmelek-logo.png\"],[10,\"alt\",\"Logo de Inmelek\"],[8],[9],[0,\"\\n  \"],[7,\"a\",true],[10,\"class\",\"underline text-sm text-gray-500\"],[10,\"href\",\"tel:0034943902658\"],[8],[0,\"(+34) 943 90 26 58\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inmelek-ember/templates/components/header.hbs"
    }
  });

  _exports.default = _default;
});