define("inmelek-ember/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "brands": {
      "title": "Marcas utilizadas"
    },
    "contact": {
      "form": {
        "email": "Email",
        "help": "Utilice el formulario de contacto para cualquier consulta o envie un mensaje a <a class=\"text-indigo-400 underline\" href=\"mailto:info@inmelek.com\">info@inmelek.com</a>",
        "message": "Mensaje",
        "send": "Enviar",
        "username": "Nombre"
      },
      "title": "Contacto"
    },
    "hero": {
      "images": {
        "efficiency": "Representación de la eficiencia de Inmelek con un pictograma de una hoja de trabajo con un casco de obrero.",
        "electrical-panel": "Cuadro eléctrico fabricado por Inmelek con cables verdes y azules",
        "engagement": "Representación de la calidad de Inmelek con un pictograma dandose la mano dentro de una rueda dentada.",
        "quality": "Representación del sello de calidad Inmelek con un pictograma de un cuaderno y un bolígrafo."
      },
      "introduction": {
        "p1": "INMELEK es una empresa que ofrece servicios dedicados al diseño e instalación eléctrica industrial de baja tensión. Disponemos de un equipo humano altamente cualificado, con más de quince años de experiencia en el sector, gracias al cual podemos desarrollar cualquier tipo de proyecto: armarios, elementos de campo y de control, cableado, motores, etc…",
        "p2": "Con el objetivo de prestar un servicio eficiente y de calidad, nuestro personal dinámico emplea las tecnologías y herramientas más avanzadas, además de las marcas punteras del sector y software de calidad contrastada.",
        "p3": "Nuestro compromiso es enfrentarnos a los desafíos exigidos por el mercado competitivo satisfaciendo a nuestros clientes."
      },
      "title": "Integración y montaje de automatismos eléctricos",
      "values": {
        "efficiency": "Eficiencia",
        "engagement": "Compromiso",
        "quality": "Calidad"
      }
    },
    "work": {
      "build_and_design": {
        "description": {
          "examples": {
            "l1": "Cuadros de distribución y de potencia",
            "l2": "Cuadros de alumbrado",
            "l3": "Cuadros de control (PLC)",
            "l4": "Cuadros de control de motores (CCM)",
            "l5": "Cuadros especiales"
          },
          "p1": "Elaboramos cualquier proyecto de armarios eléctricos a medida, cuidando integralmente cada aspecto desde el diseño hasta el montaje.",
          "p2": "Fabricamos cuadros de diferentes tipologías, incluidos:"
        },
        "title": "Fabricación y diseño de armarios eléctricos"
      },
      "images": {
        "electrical-panel-1": "Armario eléctrico con componentes Siemens fabricado por Inmelek",
        "electrical-panel-2": "Interior de armario electrico representando la calidad del servicio Inmelek"
      },
      "quality_engagement": {
        "description": "Proponemos todo tipo de soluciones para asegurar una correcta y conveniente instalación del producto según las necesidades: desde las pruebas en taller hasta la puesta en servicio en las instalaciones del cliente.",
        "title": "Compromiso de calidad"
      }
    }
  };
  _exports.default = _default;
});